import React, { useState } from "react";
import logo from "../assets/footer/logo.svg";
import vector1 from "../assets/footer/vector1.svg";
import vector2 from "../assets/footer/vector2.svg";
import buttonBg from "../assets/footer/buttonBg.svg";
import mobileButtonBg from "../assets/footer/mobileButtonBg.svg";
import { useMediaQuery } from "usehooks-ts";
import ContactModal from "./ContactModal";

const Footer = () => {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="flex items-start justify-start md:justify-center px-[32px] md:px-0 w-full h-[1200px] md:h-[770px] bg-[hsl(240,62%,4%)] z-10">
      <div className=" w-full h-auto md:w-[1128px] md:h-[762px] flex items-center justify-center flex-col">
        <div className="w-full md:w-[1128px] md:h-[399px] flex items-start justify-start md:justify-center pt-[80px] flex-col md:flex-row">
          <div className="flex items-start justify-between w-full md:w-[496px] md:h-[220px] flex-row md:flex-col">
            <img src={logo} alt="" />
            <div className="flex items-center justify-center gap-4">
              <img src={vector1} alt="" />
              <img src={vector2} alt="" />
            </div>
          </div>
          <div className="w-full h-auto mt-[50px] md:mt-0 md:w-[632px] md:h-[260px] flex items-start md:items-center justify-start md:justify-center flex-col">
            <div className="flex items-start justify-start md:justify-center flex-col md:flex-row gap-[30px] md:gap-0">
              <div className="flex items-start justify-start w-[210px] flex-col gap-[20px]">
                <h2 className="text-white font-[500] text-[14px] leading-[20px]">
                  Product
                </h2>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Point of Sales
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Universal Menu Integration
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Logistics
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Business Intelligence Dashboard
                </h3>
                <h2 className="text-white font-[500] text-[14px] leading-[20px]">
                  More...
                </h2>
              </div>
              <div className="flex items-start justify-start w-[210px] flex-col gap-[20px]">
                <h2 className="text-white font-[500] text-[14px] leading-[20px]">
                  Company
                </h2>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Our team
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Our values
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Blog
                </h3>
              </div>
              <div className="flex items-start justify-start w-[210px] flex-col gap-[20px]">
                <h2 className="text-white font-[500] text-[14px] leading-[20px]">
                  Resources
                </h2>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Download
                </h3>
                <h3 className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px]">
                  Documentation
                </h3>
                <h3
                  className="text-[#EFEDFD99] font-[400] text-[14px] leading-[20px] "
                  onClick={() => setOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  Contact
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto mt-[50px] md:mt-[0px] md:w-[1128px] md:h-[173px] border-y-[1px] border-gray-900 flex items-center justify-around md:justify-between flex-col md:flex-row">
          <div className="flex items-start justify-start flex-col w-full md:w-[270px]">
            <h2 className="text-white text-[24px] font-[700] leading-[60px] md:leading-[80px] md:w-[270px]">
              Join our newsletter
            </h2>
            <h3 className="text-[#EFEDFDB2] text-[14px] font-[400] leading-[20px]">
              Keep up to date with everything Megnus
            </h3>
          </div>
          <div className="flex items-center justify-center gap-4 w-full md:w-auto flex-col md:flex-row pb-8 md:pb-0 mt-[20px] md:mt-[0px]">
            <input
              className="bg-[hsl(240,62%,4%)] border-[1px] border-gray-900 rounded-[8px] h-[38px] w-full md:w-[300px] placeholder:text-[#EFEDFD99] placeholder:text-[14px] px-[10px]"
              type="text"
              placeholder="Enter  your  email"
            />
            <button className="flex items-center justify-center">
              <img
                className="absolute w-full h-[37px] md:w-[100px] md:h-[38px]"
                src={matches ? buttonBg : mobileButtonBg}
                alt=""
              />
              <h2 className="text-white font-[500]">Subscribe</h2>
            </button>
          </div>
        </div>
        <div className="w-full md:w-[1128px] md:h-[190px] flex items-start justify-start md:justify-between pt-[30px] md:pt-[55px] flex-col md:flex-row gap-[20px] md:gap-[0px]">
          <h3 className="text-[#EFEDFDB2] text-[14px] font-[400] leading-[20px]">
            Privacy Policy . Terms of Conditions
          </h3>
          <h3 className="text-[#EFEDFDB2] text-[14px] font-[400] leading-[20px]">
            Megnus LLC. All rights reserved.
          </h3>
        </div>
      </div>
      <ContactModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default Footer;
