import megnusButton from "../assets/seemless-integration-section/megnusButton.svg";
import background from "../assets/seemless-integration-section/background.svg";
import twinkle from "../assets/seemless-integration-section/twinkle.svg";
import dot from "../assets/seemless-integration-section/dot.svg";
import card1 from "../assets/seemless-integration-section/card-items/card-item-1.svg";
import card2 from "../assets/seemless-integration-section/card-items/card-item-2.svg";
import card3 from "../assets/seemless-integration-section/card-items/card-item-3.svg";
import card4 from "../assets/seemless-integration-section/card-items/card-item-4.svg";
import card5 from "../assets/seemless-integration-section/card-items/card-item-5.svg";
import seemLessBackground from "../assets/seemless-integration-section/seemlessInt.jpeg";

const SeemlessIntegrationSection = () => {
  return (
    <div className="flex items-start justify-center w-full h-[2100px] md:h-[1200px] bg-[hsl(240,62%,4%)]">
      <div className="flex items-center justify-center flex-col w-[528px] z-10 mt-[116px] md:mt-[0px]">
        <img src={megnusButton} alt="" />
        <h2 className="font-[700] text-[44px] max-w-[230px] leading-[45px] md:leading-[55px] text-center md:max-w-[410px] md:text-[40px] text-[#FFFFFF] mt-[15px]">
          Transform Your Ideas into Reality
        </h2>
        <h5 className="text-[#EFEDFD] opacity-70 text-[16px] max-w-[312px] md:max-w-none text-center font-[400] mt-[20px] md:mt-[12px]">
          Our AI-based software creator empowers you to generate customized
          applications without the need for extensive coding or programming
          knowledge. Simply input your requirements, and our intelligent
          algorithms will do the rest.
        </h5>

        <div className="outline outline-[1px] outline-slate-800 w-[352px] md:w-[578px] h-[230px] md:h-[170px] border-[6px] border-[#ffffff11] rounded-[20px] mt-[122px] flex items-center justify-center">
          <div className="w-[352px] md:w-[570px] h-[220px] md:h-[160px] rounded-[16px] border-[1px] border-slate-800 p-[24px]">
            <div className="flex items-center justify-between">
              <h3 className="text-white opacity-100 text-[15px] text-inter font-[400]">
                Hey Megnus create accounting system for me
              </h3>
              <button className="hidden md:flex items-center justify-center gap-2">
                <img src={twinkle} alt="" />
                <h3 className="bg-gradient-to-r text-[13px] from-[#FC72FF] via-[#487BFF] to-[#2CFFCC] bg-clip-text text-transparent">
                  Click to see magic
                </h3>
              </button>
            </div>

            <div className="mt-[27px] flex items-center justify-start gap-4">
              <img src={dot} alt="" />
              <h3 className="text-white opacity-100 text-[13px] text-inter font-[400] ">
                Simplify payroll processing, including calculating salaries
              </h3>
            </div>

            <div className="mt-[5px] flex items-center justify-start gap-4">
              <img src={dot} alt="" />
              <h3 className="text-white opacity-100 text-[13px] text-inter font-[400] ">
                Track inventory levels, manage stock movements, and streamline
                purchase orders
              </h3>
            </div>
          </div>
        </div>

        <div className="mt-[150px] flex items-center justify-center flex-col">
          <h3 className="text-white text-[32px] tracking-tight md:tracking-normal md:text-[40px]">
            What help we do?
          </h3>
          <div className="bg-transparent w-[300px] md:w-[900px] mt-[40px] flex items-center justify-center flex-col">
            <div className="flex items-center justify-center w-full h-auto flex-col md:flex-row">
              <div className="w-[300px] h-[164px] flex border-l-slate-700 border-l-[1px] md:border-none items-start md:items-center justify-center p-[16px] flex-col">
                <img src={card1} alt="" />
                <h2 className="font-[500] text-[16px] text-[#F4F0FF] mt-[20px]">
                  Data-Driven Insights
                </h2>
                <h4 className="font-[400] text-[16px] text-[#EFEDFD] opacity-60 text-center mt-[10px]">
                  drive efficiency, boost revenue
                </h4>
              </div>

              <div className="w-[300px] h-[1px] md:w-[1px] md:h-[164px] bg-gradient-to-l md:bg-gradient-to-b from-transparent to-slate-700"></div>

              <div className="w-[300px] h-[164px] flex border-l-slate-700 border-l-[1px] md:border-none items-start md:items-center justify-center p-[16px] flex-col">
                <img src={card2} alt="" />
                <h2 className="font-[500] text-[16px] text-[#F4F0FF] mt-[20px]">
                  Onboarding and Training
                </h2>
                <h4 className="font-[400] text-[16px] text-[#EFEDFD] opacity-60 text-center mt-[10px]">
                  Save time and resources
                </h4>
              </div>

              <div className="w-[300px] h-[1px] md:w-[1px] md:h-[164px] bg-gradient-to-l md:bg-gradient-to-b from-transparent to-slate-700"></div>

              <div className="w-[300px] h-[164px] flex border-l-slate-700 border-l-[1px] md:border-none items-start md:items-center justify-center p-[16px] flex-col">
                <img src={card3} alt="" />
                <h2 className="font-[500] text-[16px] text-[#F4F0FF] mt-[20px]">
                  Scalable Solutions
                </h2>
                <h4 className="font-[400] text-[16px] text-[#EFEDFD] opacity-60 text-center mt-[10px]">
                  Expertise to fuel your growth
                </h4>
              </div>
            </div>

            <div className="block md:hidden w-[300px] h-[1px] md:w-[1px] md:h-[164px] bg-gradient-to-l md:bg-gradient-to-t from-transparent to-slate-700"></div>

            <div className="hidden w-full h-[2px] bg-[#050218] md:flex items-center justify-center">
              <div className="w-[1296px] h-[1px] bg-gradient-to-r from-transparent via-slate-500 to-transparent"></div>
            </div>

            <div className="flex items-center justify-center w-full h-auto flex-col md:flex-row">
              <div className="w-[300px] h-[164px] flex border-l-slate-700 border-l-[1px] md:border-none items-start md:items-center justify-center p-[16px] flex-col">
                <img src={card4} alt="" />
                <h2 className="font-[500] text-[16px] text-[#F4F0FF] mt-[20px]">
                  Proactive Support
                </h2>
                <h4 className="font-[400] text-[16px] text-[#EFEDFD] opacity-60 mt-[10px] text-center">
                  dedicated support team
                </h4>
              </div>

              <div className="w-[300px] h-[1px] md:w-[1px] md:h-[164px] bg-gradient-to-l md:bg-gradient-to-t from-transparent to-slate-700"></div>

              <div className="w-[300px] h-[164px] flex border-l-slate-700 border-l-[1px] md:border-none items-start md:items-center justify-center p-[16px] flex-col">
                <img src={card5} alt="" />
                <h2 className="font-[500] text-[16px] text-[#F4F0FF] mt-[20px]">
                  Collaborative Partnership
                </h2>
                <h4 className="font-[400] text-[16px] text-[#EFEDFD] opacity-60 mt-[10px] text-center">
                  together towards shared success
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        className="absolute object-cover mt-[200px] w-[1440px] h-[850px] opacity-90 md:-mt-[20px]"
        // w-[1440px] h-[850px]
        // src={background}
        src={background}
        alt=""
      />
    </div>
  );
};

export default SeemlessIntegrationSection;
