import BlackHole from "../assets/main-section/blackhole.svg";
import Glare from "../assets/main-section/glare.png";
import TitleCover from "../assets/main-section/titleCover.svg";
import galaxy from "../assets/main-section/space-galaxy.jpeg";
import Video from "../assets/main-section/video.svg";
import FeaturesCards from "./FeaturesCards";
import background from "../assets/main-section/background.svg";
import mobileBackground from "../assets/main-section/mobileBackground.svg";
import { useMediaQuery } from "usehooks-ts";
import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

const MainSection = () => {
  const matches = useMediaQuery("(min-width: 768px)");
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    setPlayVideo(false);
    console.log("clicked outside");
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div
      className={`w-full bg-[hsl(240,62%,4%)] h-[1200px] md:h-[1300px] overflow-hidden flex items-center justify-start flex-col`}
    >
      <div
        ref={ref}
        className={`${
          playVideo ? "block" : "invisible"
        } absolute z-10 mt-[430px] md:mt-[492px] rounded-lg`}
      >
        <ReactPlayer
          controls
          width={matches ? 1200 : 348}
          height={matches ? 750 : 400}
          playing={playVideo}
          url="https://www.youtube.com/watch?v=gXFgodtr9N8"
        />
      </div>
      {/* <img
        className="absolute z-10 w-[1440px] h-[900px] -top-[105px] md:-top-[173px]"
        src={Glare}
        alt=""
      /> */}
      {/* <img
        className="absolute top-[205px] md:top-[170px] object-cover opacity-90 w-[1028px] overflow-x-hidden h-[578px] md:w-[1440px] md:h-[810px]"
        src={BlackHole}
        alt=""
      /> */}
      <img
        className="absolute top-[205px] md:top-[88px] object-cover opacity-30 w-[1028px] overflow-x-hidden h-[578px] md:w-full md:h-auto"
        src={galaxy}
        alt=""
      />
      <img
        onClick={() => setPlayVideo(true)}
        className="absolute top-[490px] md:top-[570px] cursor-pointer"
        src={matches ? background : mobileBackground}
        alt=""
      />
      <div className="z-20 mt-[80px] gap-[8px] md:gap-[20px] md:mt-[120px] flex items-center justify-center flex-col">
        <h4 className="flex items-center justify-center gap-1">
          <img src={TitleCover} alt="" />
        </h4>
        <h2 className="font-[700] text-white mt-[16px] text-[44px] tracking-tight max-w-[320px] text-center leading-[45px] md:max-w-none md:text-[64px]">
          Digital Transformation Suite
        </h2>
        <h4 className="text-[#efedfd] opacity-70 font-[400] flex text-center w-[320px] md:w-full items-center justify-center text-[18px] mt-[12px]">
          AI-based software creator empowers you to generate customized
          applications
        </h4>
      </div>
      {/* <div
        className={`w-full md:w-[1200px] flex items-center justify-start md:h-[392px] scrollbar-hide mt-[620px] md:mt-[920px] z-10 opacity-70 overflow-y-hidden overflow-x-scroll`}
      >
        <FeaturesCards />
      </div> */}
    </div>
  );
};

export default MainSection;
