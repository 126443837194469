import React, { useState } from "react";
import background from "../assets/thinkbetter-section/background.svg";
import button from "../assets/thinkbetter-section/button.svg";
import getStarted from "../assets/thinkbetter-section/getStarted.svg";
import galaxy from "../assets/main-section/space-galaxy.jpeg";
import ContactModal from "./ContactModal";

const ThinkBetterSection = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="flex items-start justify-center w-full h-[600px] md:h-[810px] bg-[hsl(240,62%,4%)]">
      {/* <img
        className="absolute object-cover w-[1028px] h-[578px] md:h-[810px] md:w-[1440px] opacity-100 -mt-[90px]"
        src={background}
        alt=""
      /> */}
      <img
        className="absolute object-cover w-[1028px] h-[578px] md:h-auto md:w-full opacity-20"
        src={galaxy}
        alt=""
      />
      <div className="flex items-center justify-center flex-col w-full md:w-[890px] z-10 mt-[200px] md:mt-[300px] gap-[12px]">
        <div className="flex justify-center items-center">
          <img className="absolute" src={getStarted} alt="" />
        </div>
        <h2 className="text-[32px] max-w-[309px] md:max-w-[475px] text-center leading-[40px] md:leading-[60px] font-[700] md:font-[800] md:text-[40px] text-[#FFFFFF] mt-[20px] md:mt-[15px] tracking-tight">
          Transform with Megnus
        </h2>
        <h5 className="text-[#EFEDFD] opacity-70 max-w-none md:max-w-[800px] text-[16px] md:w-[800px] text-center font-[400] mt-[12px]">
          We are passionate about driving digital transformation through
          AI-powered software solutions. Join us on this journey to unlock the
          full potential of your business. Contact us today to discover how our
          expertise can propel your organization into the future.
        </h5>
        <div
          className="flex items-center justify-center mt-[48px] cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <img className="absolute" src={button} alt="" />
        </div>
      </div>
      <ContactModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default ThinkBetterSection;
