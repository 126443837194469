import React from "react";
import background from "../assets/superpower-section/background.svg";
import buttonBg from "../assets/superpower-section/buttonBg.svg";
import div1Bg from "../assets/superpower-section/div-1-background.svg";
import div2Bg from "../assets/superpower-section/div-2-background.svg";
import div1Icon from "../assets/superpower-section/div-1-icon.svg";
import div2Icon from "../assets/superpower-section/div-2-icon.svg";

const SuperPowerSection = () => {
  return (
    <div className="flex items-start justify-center w-full h-[1000px] md:h-[800px] bg-[hsl(240,62%,4%)]">
      <img
        className="absolute object-cover w-[806px] h-[850px] opacity-80"
        src={background}
        alt=""
      />
      <div className="flex items-center justify-center flex-col w-[800px] z-10 mt-[440px] gap-[20px] md:gap-[20px]">
        {/* <div className="flex justify-center items-center">
          <img className="absolute" src={buttonBg} alt="" />
          <h3 className="text-[14px] text-white font-[500] opacity-60">
            All your notes, connected
          </h3>
        </div> */}
        <h2 className="text-[32px] max-w-[273px] md:max-w-[430px] text-center leading-[40px] md:leading-[50px] font-[700] md:font-[800] md:text-[40px] text-[#FFFFFF] mt-[15px] tracking-tight">
          Align the System with Your Processes
        </h2>
        <h5 className="text-[#EFEDFD] opacity-70 text-[16px] text-center font-[400] max-w-[300px] md:max-w-[800px] mt-[30px] md:mt-[12px]">
          Our SaaS product provides an extensive range of customization options,
          allowing you to mold it into the ideal software to manage your data.
          Tailor the interface, workflows, data fields, and functionalities to
          match your specific needs. Say goodbye to rigid systems that force you
          to adjust your processes – now you can have software that adapts to
          your organization.
        </h5>

        {/* <div className="flex items-center justify-center w-full flex-col md:flex-row md:w-[936px] md:h-[384px] mt-[68px]">
          <div className="flex items-start justify-center flex-col">
            <img src={div1Bg} alt="" />
            <div className="flex items-start justify-start flex-col px-8">
              <img src={div1Icon} alt="" />
              <div className="flex items-center justify-center mt-[18px] gap-1">
                <span className="font-[500] text-[16px] text-white">
                  Your thoughts connected.
                </span>
                <h3 className="hidden md:block font-[400] text-[16px] text-[#EFEDFDB2]">
                  Backlink your notes to
                </h3>
              </div>
              <h3 className="hidden md:block font-[400] text-[16px] text-[#EFEDFDB2]">
                make everything easily searchable.
              </h3>
              <h3 className="block md:hidden font-[400] text-[16px] text-[#EFEDFDB2]">
                Backlink your notes to make everything easily searchable.
              </h3>
            </div>
          </div>
          
          <div className="hidden md:block w-[1px] h-[384px] bg-gradient-to-b from-transparent via-slate-700 to-transparent"></div>
          <div className="flex items-start justify-center flex-col">
            <img src={div2Bg} alt="" />
            <div className="flex items-start justify-start flex-col px-8">
              <img src={div2Icon} alt="" />
              <div className="flex items-center justify-center mt-[18px] gap-1">
                <span className="font-[500] text-[16px] text-white">
                  Frictionless thought capture.
                </span>
                <h3 className="hidden md:block font-[400] text-[16px] text-[#EFEDFDB2]">
                  Record your ideas and
                </h3>
              </div>
              <h3 className="hidden md:block font-[400] text-[16px] text-[#EFEDFDB2]">
                to-do’s easily, so you never miss a thing.
              </h3>
              <h3 className="block md:hidden font-[400] text-[16px] text-[#EFEDFDB2]">
                Record your ideas and to-do’s easily, so you never miss a thing.
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SuperPowerSection;
