import React, { useState } from "react";
import Logo from "../assets/main-section/Logo.svg";
import buttonBg from "../assets/main-section/buttonBg.png";
import smallButtonBg from "../assets/main-section/smallButtonBg.svg";
import hamburgerMenu from "../assets/main-section/hamburgerMenu.svg";
import buttonBg1 from "../assets/footer/buttonBg.svg";
import mobileButtonBg from "../assets/footer/mobileButtonBg.svg";
import { useMediaQuery } from "usehooks-ts";
import Modal from "@mui/material/Modal";
import axios from "axios";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import ContactModal from "./ContactModal";

const NavBar = () => {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="w-full h-[86px] bg-[hsl(240,62%,4%)] flex items-center justify-between md:justify-center">
      <div className="w-full md:w-[1296px] mx-[20px] md:mx-auto h-[78px] md:h-[86px] flex items-center justify-between">
        <div className="flex items-center justify-center">
          <img src={Logo} alt="" />
        </div>
        <div className="hidden md:flex items-center justify-center w-[430px] h-[40px] bg-[#0C071F] border-[2px] border-[#1F1B30] rounded-full px-[24px] py-[10px] gap-[16px]">
          <Link
            className="font-inter text-[14px] text-white z-20 cursor-pointer"
            to="transform"
            offset={-70}
            spy={true}
            smooth={true}
            duration={500}
          >
            Transform
          </Link>
          <Link
            className="font-inter text-[14px] text-white z-20 cursor-pointer"
            to="scalability"
            spy={true}
            smooth={true}
            duration={500}
          >
            Scalability
          </Link>
          <Link
            className="font-inter text-[14px] text-white z-20 cursor-pointer"
            to="information"
            offset={350}
            spy={true}
            smooth={true}
            duration={500}
          >
            Information
          </Link>
          <Link
            className="font-inter text-[14px] text-white z-20 cursor-pointer"
            to="pricing"
            spy={true}
            smooth={true}
            duration={500}
          >
            Pricing
          </Link>
          <Link
            className="font-inter text-[14px] text-white z-20 font-sans cursor-pointer"
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
          >
            Contact
          </Link>
        </div>
        <div className="flex items-center justify-center md:gap-[16px]">
          <button
            className="hidden md:block font-inter text-[14px] text-white z-20"
            onClick={() =>
              (window.location.href = "https://megnus.cloubuzz.com")
            }
          >
            <a href="#">Login</a>
          </button>
          <button
            onClick={() => setOpen(true)}
            className="flex items-center justify-center mx-8 z-30"
          >
            <img
              className="absolute"
              src={matches ? buttonBg : smallButtonBg}
            ></img>
            <h3 className="font-inter text-[14px] text-white z-10">Contact</h3>
          </button>
          <img className="md:hidden" src={hamburgerMenu} alt="" />
        </div>
      </div>
      <ContactModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default NavBar;
