import React, { useState } from "react";
import div1bg from "../assets/pricing-section/div1bg.png";
import div2bg from "../assets/pricing-section/div2bg.png";
import div3bg from "../assets/pricing-section/div3bg.png";
import buttonBg1 from "../assets/pricing-section/buttonBg1.png";
import buttonBg2 from "../assets/pricing-section/buttonBg2.png";
import buttonBg3 from "../assets/pricing-section/buttonBg3.png";
import teamsIcon from "../assets/pricing-section/teamsIcon.svg";

const PricingSection = () => {
  const [payment, setPayment] = useState<string>("anually");
  return (
    <div className="flex items-start justify-center w-full h-[2800px] md:h-[1200px] bg-[hsl(240,62%,4%)]">
      <div className="flex items-center justify-center flex-col w-full md:w-[890px] z-10">
        <h2 className="text-[32px] max-w-[273px] md:max-w-none text-center md:text-start leading-[40px] md:leading-[66px] font-[700] md:font-[800] md:text-[40px] text-[#FFFFFF] mt-[20px] md:mt-[15px] tracking-tight">
          Build Better Relationships
        </h2>
        <h5 className="text-[#EFEDFDB2] opacity-70 text-[16px] w-[614px] text-center max-w-[333px] leading-[24px] md:max-w-none font-[400] mt-[20px] md:mt-[20px]">
          It's free to get started — no credit card required. Upgrade to access
          unlimited search history, unlock additional features, and support
          privacy-focused independent software.
        </h5>
        <div className="flex items-center justify-between border-[2px] bg-[#171523] rounded-full border-slate-800 w-[190px] h-[36px] mt-[70px]">
          <div
            onClick={() => setPayment("monthly")}
            className={`cursor-pointer px-[12px] text-white text-[11px] flex items-center justify-center font-bold h-[36px] rounded-full ${
              payment === "monthly" &&
              "border-[2px] bg-[#25242E] border-slate-700"
            }`}
          >
            Pay monthly
          </div>
          <div
            onClick={() => setPayment("anually")}
            className={`cursor-pointer px-[12px] text-white text-[11px] flex items-center justify-center font-bold h-[36px] rounded-full ${
              payment === "anually" &&
              "border-[2px] bg-[#25242E] border-slate-700"
            }`}
          >
            Pay anually
          </div>
        </div>

        <div className="md:w-[1150px] flex items-center justify-between mt-[70px] flex-col md:flex-row gap-[32px] md:gap-[0px]">
          <div className="h-[800px] w-[370px] border-[2px] border-[#25242E] rounded-[24px] flex items-center justify-start flex-col">
            <img className="absolute opacity-40" src={div1bg} alt="" />
            <div className="p-[36px] flex items-center justify-center flex-col">
              <div className="text-white text-[16px] font-[700] leading-[28px]">
                Starter
              </div>
              <div className="text-white mt-[34px] text-[40px] leading-[48px] font-[700]">
                {payment === "anually" ? "$23" : "$29"}
              </div>
              <div className="text-[#EFEDFDB2] opacity-70">monthly/user</div>
              <div className="flex items-center justify-center mt-[15px] border-b-[1px] border-b-[#25242E] pb-[38px] opacity-85 z-10">
                <img className="cursor-pointer" src={buttonBg1} alt="" />
              </div>
              <div className="flex items-center justify-between p-[16px] flex-col h-full gap-[24px] mt-[32px]">
                <h3 className="font-[700] text-[16px] text-white">
                  All Core Features:
                </h3>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  Instantly create and deploy one software application.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  Access to essential modules for user management, data
                  management, workflow automation, reporting, and basic
                  customization.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  Access to many ready to use modules i..e Task Management, HRM,
                  CRM, Sales Management
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Upto 10,000 Records / user.
                </h5>
              </div>
            </div>
          </div>
          <div className="h-[800px] w-[370px] border-[2px] border-[#25242E] rounded-[24px]">
            <img className="absolute" src={div2bg} alt="" />
            <div className="p-[36px] flex items-center justify-center flex-col">
              <div className="text-white text-[16px] font-[700] leading-[28px]">
                Growth
              </div>
              <div className="text-white mt-[34px] text-[40px] leading-[48px] font-[700]">
                {payment === "anually" ? "$79" : "$99"}
              </div>
              <div className="text-[#EFEDFDB2] opacity-70">monthly/user</div>
              <div className="flex items-center justify-center mt-[15px] border-b-[1px] border-b-[#25242E] pb-[38px] opacity-85 z-10">
                <img className="cursor-pointer" src={buttonBg2} alt="" />
              </div>
              <div className="flex items-center justify-between p-[16px] flex-col h-full gap-[24px] mt-[32px]">
                <h3 className="font-[700] text-[16px] text-white">
                  Everything in Starter, plus:
                </h3>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  Unlimited Software Generation: Create and deploy multiple
                  modules & forms using the AI-driven platform.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Priority Support: Dedicated support with faster response times
                  and priority handling.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Analytics and Insights: Gain valuable insights and analytics
                  to optimize software performance.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Upto 50,000 Records / user.
                </h5>
              </div>
            </div>
          </div>
          <div className="h-[800px] w-[370px] border-[2px] border-[#25242E] rounded-[24px]">
            <img className="absolute opacity-40" src={div3bg} alt="" />
            <div className="p-[36px] flex items-center justify-center flex-col">
              <div className="text-white text-[16px] font-[700] leading-[28px]">
                Enterprise
              </div>
              <img className="mt-[24px]" src={teamsIcon} alt="" />
              <div className="flex items-center justify-center mt-[33px] border-b-[1px] border-b-[#25242E] pb-[38px] opacity-85 z-10">
                <img className="cursor-pointer" src={buttonBg3} alt="" />
              </div>
              <div className="flex items-center justify-between p-[16px] flex-col h-full gap-[24px] mt-[32px]">
                <h3 className="font-[700] text-[16px] text-white">
                  Everything in Growth, plus:
                </h3>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  Enterprise-level Software Generation: Generate and deploy
                  software applications at scale for your organization.
                </h5>

                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70">
                  24/7 Premium Support with the highest priority response times.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Advanced Security measures.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Custom Pricing: Contact our sales team for a personalized
                  quote.
                </h5>
                <h5 className="text-[16px] leading-[28px] text-[#EFEDFDB2] opacity-70 w-full">
                  Unlimited Records / user.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
