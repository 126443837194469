import React from "react";
import tetris from "../assets/tetris-section/tetris.svg";
import tetrisKeys from "../assets/tetris-section/tetrisKeys.svg";
import button from "../assets/tetris-section/button.svg";
import buttonBg from "../assets/tetris-section/buttonBg.svg";

const TetrisSection = () => {
  return (
    <div className="flex items-start justify-center w-full h-[950px] md:h-[916px] bg-[hsl(240,62%,4%)]">
      <img
        className="absolute object-cover h-[640px] w-[896px] opacity-100 mt-[250px] md:mt-[160px]"
        src={tetris}
        alt=""
      />
      {/* <img
        className="hidden md:block absolute h-[24px] w-[1920px] opacity-100 mt-[810px]"
        src={tetrisKeys}
        alt=""
      /> */}

      <div className="flex items-center justify-center flex-col w-[890px] z-10 mt-[150px] gap-[20px]">
        {/* <div className="flex justify-center items-center">
          <img className="absolute" src={button} alt="" />
        </div> */}
        <h2 className="text-[32px] max-w-[309px] md:max-w-[375px] text-center leading-[40px] md:leading-[50px] font-[700] md:font-[800] md:text-[40px] text-[#FFFFFF] mt-[20px] md:mt-[15px] tracking-tight">
          The power of Pay as you Grow!
        </h2>
        <h5 className="text-[#EFEDFD] opacity-70 max-w-[290px] text-[16px] md:max-w-[600px] w-[800px] text-start font-[400] mt-[12px]">
          <span className="text-white font-extrabold">Effortless scaling:</span>
          Our AI-powered software seamlessly accommodates rapid growth and
          large-scale data processing requirements.
        </h5>
        <h5 className="text-[#EFEDFD] opacity-70 max-w-[290px] text-[16px] md:max-w-[600px] w-[800px] text-start font-[400] mt-[12px]">
          <span className="text-white font-extrabold">
            Future-proof technology:
          </span>
          Our software ensures your business stays adaptable and prepared for
          future market demands.
        </h5>
        <h5 className="text-[#EFEDFD] opacity-70 max-w-[290px] text-[16px] md:max-w-[600px] w-[800px] text-start font-[400] mt-[12px]">
          <span className="text-white font-extrabold">
            Streamlined operations:
          </span>
          an Our software eliminates resource limitations, enabling you to
          optimize efficiency and focus on core business operations.
        </h5>
        <h5 className="text-[#EFEDFD] opacity-70 max-w-[290px] text-[16px] md:max-w-[600px] w-[800px] text-start font-[400] mt-[12px]">
          <span className="text-white font-extrabold">
            Efficient data processing:
          </span>{" "}
          Our AI-powered software efficiently handles and analyzes large volumes
          of data, providing valuable insights for informed decision-making.
        </h5>
        {/* <button className="flex items-center justify-center mt-[48px]">
          <img className="absolute" src={buttonBg} alt="" />
          <h2 className="text-white text-[14px]">Take the course</h2>
        </button> */}
      </div>
    </div>
  );
};

export default TetrisSection;
