import NavBar from "./components/NavBar";
import MainSection from "./components/MainSection";
import SeemlessIntegrationSection from "./components/SeemlessIntegrationSection";
import SuperPowerSection from "./components/SuperPowerSection";
import NeverLoseInformation from "./components/NeverLoseInformation";
import LockPictureSection from "./components/LockPictureSection";
import MoreMeetingsSection from "./components/MoreMeetingsSection";
import OtherAppSection from "./components/OtherAppSection";
import PricingSection from "./components/PricingSection";
import WallOfLoveSection from "./components/WallOfLoveSection";
import AboutMegnusSection from "./components/AboutMegnusSection";
import TetrisSection from "./components/TetrisSection";
import ThinkBetterSection from "./components/ThinkBetterSection";
import Footer from "./components/Footer";
import * as Scroll from "react-scroll";
import toast, { Toaster } from "react-hot-toast";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

function App() {
  return (
    <div className={`flex items-center flex-col justify-start w-full h-auto`}>
      <NavBar />
      <div className="hidden md:block w-full h-[2px] bg-[#050218]">
        <div className=" w-[1296px] h-[1px] bg-gradient-to-r from-transparent via-slate-500 to-transparent"></div>
      </div>
      <MainSection />
      <Element name="transform"></Element>
      <SeemlessIntegrationSection />
      <Element name="scalability"></Element>
      <SuperPowerSection />
      <Element name="information"></Element>
      {/* <NeverLoseInformation /> */}
      {/* <LockPictureSection /> */}
      {/* <MoreMeetingsSection /> */}
      {/* <OtherAppSection /> */}
      <Element name="pricing"></Element>
      <PricingSection />
      {/* <WallOfLoveSection />
      <AboutMegnusSection /> */}
      <TetrisSection />
      <Element name="contact"></Element>
      <ThinkBetterSection />
      <Footer />
      <Toaster />
    </div>
  );
}

export default App;
